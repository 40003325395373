import SvgIcon from "@mui/material/SvgIcon";
import { ReactSVG } from "react-svg";
import arrowBack from "../assets/img/arrow-back.svg";
import arrowIcon from "../assets/img/icons/arrow.png";
import dateIcon from "../assets/img/icons/date.png";
import inspectionIcon from "../assets/img/icons/inspectionNo.png";
import requestIcon from "../assets/img/icons/requestNo.png";
import Outdoors from "../assets/img/outdoors.png";
import Satellite from "../assets/img/satellite.png";
import Light from "../assets/img/light.png";
import Dark from "../assets/img/dark.png";
import Streets from "../assets/img/streets.png";
import Button from "@mui/material/Button";
import Low from "../assets/img/icons/low.png";
import Medium from "../assets/img/icons/medium.png";
import High from "../assets/img/icons/high.png";
import { Chip } from "@mui/material";

import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import OutlinedInput from "@mui/material/OutlinedInput";

export const CustomSelect = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: "10px",
  height: "35px",
  minHeight: "35px",
  fontFamily: "'Space Grotesk', sans-serif",
  fontSize: "12px",
  "& .MuiOutlinedInput-input": {
    padding: "0 12px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "10px",
    borderColor: "#ced4da",
    borderWidth: "1px",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ced4da",
    borderWidth: "1px",
  },
  "&.MuiOutlinedInput-root": {
    boxShadow: "none !important",
  },
  "&.Mui-focused": {
    borderColor: "#ced4da",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ced4da",
  },
  "&:hover": {
    backgroundColor: "rgba(204, 204, 204, 0.3)",
    boxShadow: "0px 0px 8px rgba(195, 194, 194, 0.2)",
  },
}));

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#27c59b",
    "&:hover": {
      backgroundColor: alpha("#27c59b", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#27c59b",
  },
}));

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const STAGE_API_URL = "https://stage.detect-inspections.com";
export const PROD_API_URL = "https://service.detect-inspections.com";
export const TEST_API_URL = "http://34.71.230.126";

export const chartData1 = {
  datasets: [
    {
      data: [1, 2, 3],
      backgroundColor: [
        "rgba(221, 44, 0, 0.8)",
        "rgba(249, 168, 37, 0.8)",
        "rgba(102, 187, 106, 0.8)",
      ],
      borderColor: [
        "rgba(221, 44, 0, 1)",
        "rgba(249, 168, 37, 1)",
        "rgba(102, 187, 106, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export const StatusConstants = {
  1: "PENDING",
  2: "PHOTOS UPLOADED",
  3: "ANNOTATED",
  4: "REVIEWED",
  5: "REJECTED",
  6: "APPROVED",
};

export const getStatusStyles = (val) => {
  switch (val) {
    case 1:
      return {
        backgroundColor: "rgba(204, 204, 204, 0.8)",
        color: "#000000",
      };
    case 2:
      return {
        backgroundColor: "rgba(159, 197, 248, 0.5)",
        color: "#0a4273",
      };
    case 3:
      return {
        backgroundColor: "#fee2d3",
        color: "#ff8039",
      };
    case 4:
      return {
        backgroundColor: "rgba(188, 119, 66, 0.45)",
        color: "#633900",
      };
    case 5:
      return {
        backgroundColor: "#ffd7d7",
        color: "#dd2c00",
      };
    case 6:
      return {
        backgroundColor: "#ddffcd",
        color: "#007f00",
      };
    default:
      return {}; // Default styles
  }
};

export const UploadStatusConstants = {
  0: "FAILED",
  1: "SUCCESSFUL",
};

export const getUploadStatusStyles = (val) => {
  switch (val) {
    case 0:
      return {
        backgroundColor: "#ffd7d7",
        color: "#dd2c00",
      };
    case 1:
      return {
        backgroundColor: "#ddffcd",
        color: "#007f00",
      };
    default:
      return {};
  }
};

export const UPLOAD_BATCH_SIZE = 10;

export const ThreeDotsIcon = (props) => {
  return (
    <SvgIcon {...props} width="50" height="20" viewBox="0 0 50 20">
      <circle cx="10" cy="10" r="4" />
      <circle cx="25" cy="10" r="4" />
      <circle cx="40" cy="10" r="4" />
    </SvgIcon>
  );
};

export const PrevArrow = ({ onClick, isDisabled }) => {
  return (
    <div
      className="back-carousal"
      onClick={onClick}
      style={{ display: isDisabled ? "none" : "" }}
    >
      <ReactSVG src={arrowBack} />
    </div>
  );
};

export const NextArrow = ({ onClick, isDisabled }) => {
  return (
    <div
      className="front-carousal"
      onClick={onClick}
      style={{ display: isDisabled ? "none" : "" }}
    >
      <ReactSVG src={arrowBack} />
    </div>
  );
};

export const statusOptions = [
  {
    label: "PENDING",
    backgroundColor: "rgba(204, 204, 204, 0.8)",
    color: "#000000",
    value: 1,
  },
  {
    label: "PHOTOS UPLOADED",
    backgroundColor: "rgba(159, 197, 248, 0.5)",
    color: "#0a4273",
    value: 2,
  },
  {
    label: "ANNOTATED",
    backgroundColor: "#fee2d3",
    color: "#ff8039",
    value: 3,
  },
  {
    label: "REVIEWED",
    backgroundColor: "rgba(188, 119, 66, 0.45)",
    color: "#633900",
    value: 4,
  },
  {
    label: "REJECTED",
    backgroundColor: "#ffd7d7",
    color: "#dd2c00",
    value: 5,
  },
  {
    label: "APPROVED",
    backgroundColor: "#ddffcd",
    color: "#007f00",
    value: 6,
  },
];

export const defectOptions = [
  { label: "High", icon: High, rotation: 180, value: "high" },
  { label: "Medium", icon: Medium, rotation: 270, value: "medium" },
  { label: "Low", icon: Low, rotation: 360, value: "low" },
];

export const components = ["Cross Arm", "Guy", "Hardware", "Insulator", "Pole"];

export const inspectionsSortOptions = [
  { label: "Oldest", icon: requestIcon, value: "created_at" },
  { label: "Newest", icon: requestIcon, value: "-created_at" },
  {
    label: "Line Number (A-Z)",
    icon: inspectionIcon,
    value: "powerline__line_number",
  },
  {
    label: "Line Number (Z-A)",
    icon: inspectionIcon,
    value: "-powerline__line_number",
  },
];

export const infoTabOptions = {
  1: "DEFAULT",
  2: "TRANSMISSION",
  3: "DISTRIBUTION",
  4: "COMPONENT",
};

export const severityOptions = [
  { id: 1, severity: "Good", color: "#848484" },
  { id: 2, severity: "Low", color: "#66bb6a" },
  { id: 3, severity: "Medium", color: "#f9a825" },
  { id: 4, severity: "High", color: "#dd2c00" },
];

export const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#ffffff"), // Text color
  backgroundColor: "#ffffff", // Background color
  borderRadius: "16px", // Rounded corners
  border: "2px solid #333", // Dark grey border
  padding: "4px 10px",
  fontSize: "11.5px",
  "&:hover": {
    backgroundColor: "#e0e0e0", // Slightly darker shade on hover
  },
  width: "141px",
}));

export const annotationSeverityOptions = [
  {
    id: 1,
    severity: "Good",
    color: "rgb(202, 202, 202)",
    fill: "rgb(202, 202, 202, 0.1)",
  },
  {
    id: 2,
    severity: "Low",
    color: "rgb(102, 187, 106)",
    fill: "rgb(102, 187, 106, 0.1)",
  },
  {
    id: 3,
    severity: "Medium",
    color: "rgb(249, 168, 37)",
    fill: "rgb(249, 168, 37, 0.1)",
  },
  {
    id: 4,
    severity: "High",
    color: "rgb(221, 44, 0)",
    fill: "rgb(221, 44, 0, 0.1)",
  },
];

export const mapStyles = {
  light: {
    mapUrl: "mapbox://styles/mapbox/light-v11",
    image: Light,
  },
  dark: {
    mapUrl: process.env.REACT_APP_MAPBOX_URL,
    image: Dark,
  },
  streets: {
    mapUrl: "mapbox://styles/mapbox/streets-v12",
    image: Streets,
  },
  outdoor: {
    mapUrl: "mapbox://styles/mapbox/outdoors-v12",
    image: Outdoors,
  },
  satellite: {
    mapUrl: "mapbox://styles/mapbox/satellite-v9",
    image: Satellite,
  },
};

export const getSeverityTag = (severity) => {
  let color = "";
  let label = "";
  switch (severity) {
    case 2:
      color = "rgb(102, 187, 106)";
      label = "Low";
      break;
    case 3:
      color = "rgb(249, 168, 37)";
      label = "Medium";
      break;
    case 4:
      color = "rgb(221, 44, 0)";
      label = "High";
      break;
    default:
      color = "rgb(202, 202, 202)";
      label = "Good";
  }
  return (
    <Chip
      label={label}
      sx={{
        backgroundColor: color,
        color: "white",
        fontWeight: "semi-bold",
        fontSize: "13px",
        marginRight: "200px",
        width: "72px",
      }}
    />
  );
};
