import React, { useEffect, useRef, useState, useContext } from "react";
import { Marker } from "react-map-gl";
import currentMarker from "../../assets/img/icons/currentMarker.png";
import drone from "../../assets/img/icons/drone.png";
import selected from "../../assets/img/icons/clicked.png";
import Rearrange from "../../assets/img/icons/rearrange.png";
import infoIcon from "../../assets/img/icons/info.png";
import Tooltip from "@mui/material/Tooltip";
import Popup from "../../helpers/Popup";
import { showNotificationToast } from "../../helpers/Messages";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import AuthContext from "../../pages/auth/AuthContext";
import BaseMapComponent from "./BaseMap";

const ReassignStructureMap = ({
  selectedItemId,
  checkedItems,
  selectedCount,
  setSelectedCount,
  setReassignImages,
  checkedFilenames,
  mapToolHeight,
}) => {
  const mapRef = useRef(null);

  const authContext = useContext(AuthContext);

  const [selectedStructure, setSelectedStructure] = useState(null);
  const [mapData, setMapData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [reassignPopup, setReassignPopup] = useState(false);

  const [visibleMarkers, setVisibleMarkers] = useState([]);
  const [mapZoom, setMapZoom] = useState(14);

  const handleStateReset = () => {
    setSelectedStructure(null);
    setReassignPopup(false);
  };

  useEffect(() => {
    handleStateReset();
  }, [selectedItemId]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const data = await window.apiHelper.getMapData(selectedItemId);
        setMapData(data);
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedItemId]);

  const handleMarkerClick = (structure) => {
    setSelectedStructure((prevSelected) =>
      prevSelected === structure ? null : structure
    );
  };

  const handleReassignPopup = () => {
    setReassignPopup(true);
  };

  const handleReassignPopupAccept = () => {
    const idsArray = Object.keys(checkedItems);
    const data = {
      image_ids: idsArray,
      destination_structure_id: selectedStructure.id,
    };
    handleReorganizeImages(data);
  };

  const handleReorganizeImages = async (data) => {
    try {
      const response = await window.apiHelper.postRestructingImages(data);
      if (response) {
        setReassignImages(true);
        setReassignPopup(false);
        showNotificationToast(
          ` ${selectedCount} ${
            selectedCount > 1 ? "images" : "image"
          } reassigned to ${selectedStructure.identifier} `
        );
        setSelectedCount(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReassignPopupReject = () => {
    setReassignPopup(false);
  };

  return (
    <>
      {reassignPopup && (
        <Popup
          text={
            <>
              <p>
                Are you sure you want to reassign these {selectedCount} images
                from {mapData?.current_structure.identifier} to{" "}
                {selectedStructure.identifier} ?
              </p>
              <p>Selected images:</p>
              <SimpleBar
                className="custom-simplebar"
                style={{
                  maxHeight: 125,
                  marginRight: 5,
                }}
              >
                <ul className="no-dots-list">
                  {checkedFilenames.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </SimpleBar>
            </>
          }
          onAccept={handleReassignPopupAccept}
          onReject={handleReassignPopupReject}
          type="option"
        />
      )}

      <BaseMapComponent
        innerRef={mapRef}
        onVisibleMarkersChange={setVisibleMarkers}
        mapData={mapData}
        loading={loading}
        mapToolHeight={mapToolHeight}
        setMapZoom={setMapZoom}
      >
        {authContext.permissions.includes("can_reassign_images") && (
          <Tooltip
            title={
              selectedStructure
                ? selectedCount > 0
                  ? "Reassign"
                  : "No Image Selected"
                : "Select a structure"
            }
            arrow
            placement="right"
          >
            <button
              className="reassign-button"
              onClick={handleReassignPopup}
              disabled={
                selectedStructure ? (selectedCount > 0 ? false : true) : true
              }
            >
              <img src={Rearrange} alt="rearrange" style={{ width: "25px" }} />
            </button>
          </Tooltip>
        )}

        {selectedStructure && (
          <div className="selected-structure-heading">
            Selected Structure: {selectedStructure.identifier}
          </div>
        )}
        <Marker
          latitude={mapData?.image_metadata.pass.lat}
          longitude={mapData?.image_metadata.pass.lon}
          anchor="center"
        >
          <div
            style={{
              transform: `rotate(${mapData?.image_metadata.pass.heading_degree}deg)`,
              pointerEvents: "none",
            }}
          >
            <img src={drone} alt="drone marker" />
          </div>
        </Marker>
        {mapZoom >= 15 &&
          visibleMarkers.map((structure) => (
            <Marker
              key={structure?.identifier}
              latitude={structure?.lat}
              longitude={structure?.lon}
              anchor="center"
              onClick={() => handleMarkerClick(structure)}
            >
              {JSON.stringify(selectedStructure) ===
              JSON.stringify(structure) ? (
                <div style={{ position: "relative" }}>
                  <img
                    src={selected}
                    alt="selected marker"
                    style={{ width: "21px", height: "22px" }}
                  />
                  <span className="selected-structure-subheading">
                    {structure.identifier}
                  </span>
                </div>
              ) : (
                <Tooltip
                  title={structure.identifier}
                  followCursor
                  placement="top"
                >
                  <div
                    className="dot-marker"
                    style={{
                      backgroundColor: "#ff8039",
                    }}
                  />
                </Tooltip>
              )}
            </Marker>
          ))}
        <Marker
          latitude={mapData?.current_structure.lat}
          longitude={mapData?.current_structure.lon}
          anchor="center"
        >
          <Tooltip
            title={mapData?.current_structure.identifier}
            followCursor
            placement="top"
          >
            <img src={currentMarker} alt="current marker" />
          </Tooltip>
        </Marker>
        {mapZoom >= 15 && (
          <div className="map-info-button">
            <Tooltip
              title={"Reassign images by selecting a structure"}
              placement="top"
              arrow
            >
              <img
                src={infoIcon}
                alt="Info Icon"
                style={{ width: "24px", cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        )}
      </BaseMapComponent>
    </>
  );
};

export default ReassignStructureMap;
