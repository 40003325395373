import React, { useEffect, useRef, useState, useContext } from "react";
import Map, { Marker } from "react-map-gl";
import { LngLatBounds } from "mapbox-gl";
import currentMarker from "../../assets/img/icons/currentMarker.png";
import drone from "../../assets/img/icons/drone.png";
import selected from "../../assets/img/icons/clicked.png";
import Layers from "../../assets/img/icons/layers.png";
import Rearrange from "../../assets/img/icons/rearrange.png";
import Close from "../../assets/img/icons/cancel.png";
import infoIcon from "../../assets/img/icons/info.png";
import Tooltip from "@mui/material/Tooltip";
import Popup from "../../helpers/Popup";
import { showNotificationToast } from "../../helpers/Messages";
import Loader from "../../helpers/Loader";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { mapStyles, severityOptions } from "../../helpers/Constants";
import MapStyleSelector from "../../helpers/MapStyleSelector";
import AuthContext from "../../pages/auth/AuthContext";

const MapComponent = ({
  selectedItemId,
  checkedItems,
  selectedCount,
  setSelectedCount,
  setReassignImages,
  checkedFilenames,
  mapToolHeight,
  type,
  selectedRequestId,
  setSelectedStructureNo,
  setSelectedStructureId,
  setSelectedItemId,
  setAnnotationWindowLoad,
  setStructureSummaryLoad,
  selectedStructureId,
}) => {
  const mapRef = useRef(null);

  const authContext = useContext(AuthContext);

  const [selectedStructure, setSelectedStructure] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 14,
  });
  const [loading, setLoading] = useState(true);
  const [reassignPopup, setReassignPopup] = useState(false);
  const [selectedLayer, setSelectedLayer] = useState(
    localStorage.getItem("selectedMapStyle") || "light"
  );
  const [showLayers, setShowLayers] = useState(false);
  const [visibleMarkers, setVisibleMarkers] = useState([]);

  const updateVisibleMarkers = (bounds) => {
    const visible = mapData.nearby_structures.filter((structure) =>
      bounds.contains([structure.lon, structure.lat])
    );
    setVisibleMarkers(visible);
  };

  const handleStateReset = () => {
    setViewport({
      latitude: 0,
      longitude: 0,
      zoom: 14,
    });
    setReassignPopup(false);
  };

  useEffect(() => {
    handleStateReset();
  }, [selectedItemId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await window.apiHelper.getMapData(selectedItemId);
        setMapData(data);
        setViewport({
          latitude: data?.current_structure.lat,
          longitude: data?.current_structure.lon,
          zoom: 14,
        });
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedItemId]);

  useEffect(() => {
    if (mapRef.current && !loading) {
      const handleMapLoadWithDelay = () => {
        setTimeout(() => {
          handleMapLoad(); // Adjust bounds and markers after switching
        }, 100); // delay render to help with map render bug
      };
      handleMapLoadWithDelay();
    }
  }, [mapToolHeight, loading]);

  const handleMapLoad = () => {
    if (!loading && mapData && mapRef.current) {
      const { lon, lat } = mapData.current_structure;

      // Create a bounding box with a 150-meter radius around the current structure
      const bounds = new LngLatBounds(
        [lon - 0.00135, lat - 0.00135],
        [lon + 0.00135, lat + 0.00135]
      );

      mapRef.current.fitBounds(bounds, {
        animate: true,
        padding: 40,
      });

      updateVisibleMarkers(bounds);
    }
  };

  const handleMarkerClick = (structure) => {
    setSelectedStructure((prevSelected) =>
      prevSelected === structure ? null : structure
    );
  };

  const handleReassignPopup = () => {
    setReassignPopup(true);
  };

  const handleReassignPopupAccept = () => {
    const idsArray = Object.keys(checkedItems);
    const data = {
      image_ids: idsArray,
      destination_structure_id: selectedStructure.id,
    };
    handleReorganizeImages(data);
  };

  const handleReorganizeImages = async (data) => {
    try {
      const response = await window.apiHelper.postRestructingImages(data);
      if (response) {
        setReassignImages(true);
        setReassignPopup(false);
        showNotificationToast(
          ` ${selectedCount} ${
            selectedCount > 1 ? "images" : "image"
          } reassigned to ${selectedStructure.identifier} `
        );
        setSelectedCount(0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReassignPopupReject = () => {
    setReassignPopup(false);
  };

  const NavigateToStructure = async () => {
    try {
      setAnnotationWindowLoad(true);
      setStructureSummaryLoad(false);

      const newStructureNo = selectedStructure?.identifier;
      const newStructureId = selectedStructure?.id;

      setSelectedStructureId(newStructureId);
      setSelectedStructureNo(newStructureNo);
      const images = await window.apiHelper.getImagesList(
        selectedRequestId,
        selectedStructure?.id
      );
      if (images.length > 0) {
        const imageDetails = await window.apiHelper.getPhotoAnnotationDetails(
          images[0].id
        );
        setSelectedItemId(imageDetails.id);
      } else {
        setSelectedItemId("");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSelectedStructure(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type === "map") {
      setSelectedStructure(null); // Deselect the structure if toggling between map/reassign modes
    }

    // Prevent navigatetostructure trigger if toggling between map/reassign with a selected structure
    if (selectedStructure && type === "map") {
      NavigateToStructure();
    }
  }, [selectedStructure, type]);

  const getSeverityColor = (severityId) => {
    const severity = severityOptions.find((option) => option.id === severityId);
    return severity ? severity.color : "#848484"; // Default to good
  };

  const handleShowLayers = () => {
    setShowLayers(!showLayers);
    localStorage.setItem("selectedMapStyle", selectedLayer);
  };

  if (loading) {
    return (
      <div
        style={{
          position: "relative",
          height: mapToolHeight - 21,
          width: "100%",
        }}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      {reassignPopup && (
        <Popup
          text={
            <>
              <p>
                Are you sure you want to reassign these {selectedCount} images
                from {mapData?.current_structure.identifier} to{" "}
                {selectedStructure.identifier} ?
              </p>
              <p>Selected images:</p>
              <SimpleBar
                className="custom-simplebar"
                style={{
                  maxHeight: 125,
                  marginRight: 5,
                }}
              >
                <ul className="no-dots-list">
                  {checkedFilenames.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </SimpleBar>
            </>
          }
          onAccept={handleReassignPopupAccept}
          onReject={handleReassignPopupReject}
          type="option"
        />
      )}

      <div
        style={{
          width: "100%",
          height: mapToolHeight - 21,
          position: "relative",
        }}
      >
        <button className="layers-button" onClick={handleShowLayers}>
          <img
            src={!showLayers ? Layers : Close}
            alt="layers"
            style={{ width: "25px", padding: "3px" }}
          />
        </button>

        {showLayers && (
          <div className="map-style-selector">
            <MapStyleSelector
              setSelectedLayer={setSelectedLayer}
              selectedLayer={selectedLayer}
            />
          </div>
        )}

        {type === "reassign" &&
          authContext.permissions.includes("can_reassign_images") && (
            <Tooltip
              title={
                selectedStructure
                  ? selectedCount > 0
                    ? "Reassign"
                    : "No Image Selected"
                  : "Select a structure"
              }
              arrow
              placement="top"
            >
              <button
                className="reassign-button"
                onClick={handleReassignPopup}
                disabled={
                  selectedStructure ? (selectedCount > 0 ? false : true) : true
                }
              >
                <img
                  src={Rearrange}
                  alt="rearrange"
                  style={{ width: "25px" }}
                />
              </button>
            </Tooltip>
          )}

        {selectedStructure && (
          <div className="selected-structure-heading">
            Selected Structure: {selectedStructure.identifier}
          </div>
        )}
        <Map
          ref={mapRef}
          initialViewState={viewport}
          onMove={(evt) => setViewport(evt.viewState)}
          onLoad={handleMapLoad}
          style={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            outline: selectedStructure ? "5px solid #27c59b" : "none",
            borderRadius: "8px",
          }}
          mapStyle={mapStyles[selectedLayer].mapUrl}
          onMoveEnd={() => updateVisibleMarkers(mapRef.current.getBounds())}
          attributionControl={false}
        >
          <Marker
            latitude={mapData?.image_metadata.pass.lat}
            longitude={mapData?.image_metadata.pass.lon}
            anchor="center"
          >
            <div
              style={{
                transform: `rotate(${mapData?.image_metadata.pass.heading_degree}deg)`,
                pointerEvents: "none",
              }}
            >
              <img src={drone} alt="drone marker" />
            </div>
          </Marker>
          {visibleMarkers.map((structure) => (
            <Marker
              key={structure?.identifier}
              latitude={structure?.lat}
              longitude={structure?.lon}
              anchor="center"
              onClick={() => handleMarkerClick(structure)}
            >
              {JSON.stringify(selectedStructure) ===
              JSON.stringify(structure) ? (
                <div style={{ position: "relative" }}>
                  <img
                    src={selected}
                    alt="selected marker"
                    style={{ width: "21px", height: "22px" }}
                  />
                  <span className="selected-structure-subheading">
                    {structure.identifier}
                  </span>
                </div>
              ) : (
                <Tooltip
                  title={structure.identifier}
                  followCursor
                  placement="top"
                >
                  <div
                    className="dot-marker"
                    style={{
                      backgroundColor:
                        type === "map"
                          ? getSeverityColor(structure.severity)
                          : "#ff8039",
                    }}
                  />
                </Tooltip>
              )}
            </Marker>
          ))}
          <Marker
            latitude={mapData?.current_structure.lat}
            longitude={mapData?.current_structure.lon}
            anchor="center"
          >
            <Tooltip
              title={mapData?.current_structure.identifier}
              followCursor
              placement="top"
            >
              <img src={currentMarker} alt="current marker" />
            </Tooltip>
          </Marker>
          <div className="map-info-button">
            <Tooltip
              title={
                type === "map"
                  ? "Select a structure to explore inspection images and details"
                  : "Reassign images by selecting a structure"
              }
              placement="top"
              arrow
            >
              <img
                src={infoIcon}
                alt="Info Icon"
                style={{ width: "24px", cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        </Map>
      </div>
    </>
  );
};

export default MapComponent;
