import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NextArrow, PrevArrow } from "../../helpers/Constants";
import { ReactSVG } from "react-svg";
import annotated from "../../assets/img/annotated.svg";
import copysvg from "../../assets/img/copy.svg";
import chat from "../../assets/img/icons/chat.png";
import Tooltip from "@mui/material/Tooltip";
import Loader from "../../helpers/Loader";
import ChevronUp from "../../assets/img/icons/chevronUp.png";

const ImageCarousal = ({
  images,
  selectedItemId,
  setSelectedItemId,
  setShowPopUp,
}) => {
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const sliderRef = useRef(null);
  const [isFirstSet, setIsFirstSet] = useState(true);
  const [isLastSet, setIsLastSet] = useState(false);
  const [copiedItemId, setCopiedItemId] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(
    Array(images.length).fill(false)
  );
  const centeredCarousalRef = useRef(null);
  const [cardHeight, setCardHeight] = useState("auto");

  useEffect(() => {
    const updateCardHeight = () => {
      if (centeredCarousalRef.current) {
        setCardHeight(centeredCarousalRef.current.clientHeight * 0.9 + "px");
      }
    };

    updateCardHeight();

    // Update card height on window resize
    window.addEventListener("resize", updateCardHeight);

    return () => window.removeEventListener("resize", updateCardHeight);
  }, []);

  const settings = {
    arrows: true,
    speed: 500,
    infinite: false,
    slidesToShow: 9,
    slidesToScroll: 9,
    nextArrow: <NextArrow isDisabled={isLastSet} />,
    prevArrow: <PrevArrow isDisabled={isFirstSet} />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
    ],
    beforeChange: (current, next) => {
      setIsFirstSet(next === 0);
      setIsLastSet(next + settings.slidesToShow >= images.length);
    },
  };

  const handleItemHover = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleItemSelect = (itemId) => {
    setSelectedItemId(itemId);
  };

  const handleShowPopup = () => {
    setShowPopUp(true);
  };

  const copyToClipboard = (text, itemId) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setCopiedItemId(itemId);
        setTimeout(() => setCopiedItemId(null), 1500); // We can adjust this timeout if 1.5 seconds is too short/long
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  useEffect(() => {
    // Find the index of the selected item in the images array
    const initialSlide = images.findIndex((item) => item.id === selectedItemId);
    // Navigate to the selected item once the slider has been mounted
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(initialSlide - 2);
    }
  }, [images, selectedItemId]);

  useEffect(() => {
    setImageLoaded(Array(images.length).fill(false));
  }, [images]);

  const handleImageLoad = (index) => {
    setImageLoaded((current) =>
      current.map((loaded, i) => (i === index ? true : loaded))
    );
  };

  return (
    <>
      <div className="centeredCarousal" ref={centeredCarousalRef}>
        {
          <button className="show-more-button" onClick={handleShowPopup}>
            <img
              src={ChevronUp}
              alt="Image Alt Text"
              className="show-more-img"
            />
          </button>
        }

        <Slider ref={sliderRef} {...settings}>
          {images.map((item, index) => (
            <Tooltip
              key={index}
              title={
                copiedItemId === item.id ? "Copied image name!" : item.filename
              }
              style={{ cursor: "pointer" }}
              followCursor
            >
              <div
                key={index}
                className={`card ${
                  hoveredItemId === item.id ? "hovered" : ""
                } ${selectedItemId === item.id ? "selected" : ""}`}
                onMouseOver={() => handleItemHover(item.id)}
                onMouseOut={() => handleItemHover(null)}
                onClick={() => handleItemSelect(item.id)}
              >
                <>
                  {!imageLoaded[index] && <Loader />}

                  <img
                    src={item.thumbnail}
                    alt="thumbnail"
                    className="image-thumbnail"
                    onLoad={() => handleImageLoad(index)}
                    style={{ height: cardHeight }}
                  />

                  {hoveredItemId === item.id && (
                    <>
                      <div
                        className="copy-svg-container"
                        onClick={(e) => {
                          e.stopPropagation();
                          copyToClipboard(item.filename, item.id);
                        }}
                      >
                        <ReactSVG
                          src={copysvg}
                          style={{ width: "10px", height: "10px" }}
                        />
                      </div>
                    </>
                  )}
                  {item.has_chat && (
                    <div className="chat-icon-container">
                      <img
                        src={chat}
                        alt="chat-icon"
                        style={{
                          width: "20px",
                          height: "20px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                  )}
                  {item.annotated && (
                    <ReactSVG
                      src={annotated}
                      className={`annotated-sign annotated-sign-${item.max_severity}`}
                    />
                  )}
                </>
              </div>
            </Tooltip>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default ImageCarousal;
