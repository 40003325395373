import React, { Component, useContext } from "react";
import axios from "axios";
import AuthContext from "../pages/auth/AuthContext";

class ApiHelper {
  constructor(apiUrl) {
    this.apiUrlBase = apiUrl;

    this.apiUrls = {
      auth: {
        login: "/api/auth/login/",
        logout: "/api/auth/logout/",
      },
      workorders: {
        completeList: "/api/request/",
        list: "/api/request/?page=%%PAGE%%&page_size=%%PAGE_SIZE%%&ordering=id&search=%%SEARCH%%",
        inspectionsSummary: "/api/request/summary_stats/",
        heatmap: "/api/request/structure_locations/",
        view: "/api/request/%%ID%%/",
        structures: "/api/request/%%ID%%/structure/",
        tableData:
          "/api/structuretracker/?page=%%PAGE%%&ordering=structure__order, structure__identifier&page_size=%%PAGE_SIZE%%&request=%%ID%%&search=%%SEARCH%%&status=%%STATUS%%&severity=%%SEVERITY%%&feature_defect=%%COMPONENT%%",
        stats: "/api/request/%%ID%%/stats/",
        updateStatus: "/api/structuretracker/%%ID%%/",
        downloadImages:
          "/api/structure/%%STR_ID%%/export_images/?request=%%REQ_ID%%",
        downloadAllImages: "/api/request/%%ID%%/export_images/",
        downloadCSVReport: "/api/request/%%ID%%/download_report/",
        device: "/api/devices/",
        chatNotifications:
          "/api/notification/chat_notifications/?page=%%PAGE%%&page_size=%%PAGE_SIZE%%",
        updateNotifications: "/api/notification/%%ID%%/",
        bulkReadNotifications: "/api/notification/bulk_read/",
        readAllImageChatNotifications: "/api/notification/mark_chat_as_read/",
        softDeleteImages: "/api/image/soft_delete/",
        structureData: "/api/request/%%REQUEST_ID%%/get_structures_list/",
        restructingImages: "/api/image/bulk-update-structure/",
        mapData: "/api/image/%%ID%%/image_map_tool/",
      },
      annotation: {
        image: "/api/annotation/%%ID%%/image/",
        imagesList:
          "/api/image/?ordering=image_timestamp&request=%%REQUEST_ID%%&structure=%%STRUCTURE_ID%%",
        getAnnotation: "/api/image/%%ID%%/",
        postChat: "/api/chat/write/",
        updateImage: "/api/image/%%ID%%/",
        getStructureTracker:
          "/api/structuretracker/get_structure_tracker/%%REQUEST_ID%%/%%STRUCTURE_ID%%/",
      },
      info: {
        structureMetadata:
          "/api/structuretracker/get_structure_metadata/?request_id=%%REQUEST_ID%%&structure_id=%%STRUCTURE_ID%%",
        templateTypes: "/api/template/get_template_types/",
        templateSchema:
          "/api/template/get_template_schema/?request_id=%%REQUEST_ID%%&template_type=%%TEMPLATE_TYPE%%",
        overwriteData:
          "/api/structuretracker/update_structure_metadata/?request_id=%%REQUEST_ID%%&structure_id=%%STRUCTURE_ID%%&template_type=%%TEMPLATE_TYPE%%",
      },
      services: {
        orders: {
          getProviders: "/api/org/",
          getLineNumbers: "/api/linenumber/",
          getStructures: "/api/structure/?id=%%ID%%",
          create: "/api/request/",
          structureFolderId:
            "/api/imageviewer/structure_folder_id/?request_id=%%ID%%&structure_identifier=%%NAME%%",
        },
        uploads: {
          getImageUploadUrl:
            "/api/request/%%ID%%/get_upload_image_url/?filename=%%FILE%%",
          imageIngestEndPoint: "/api/imageingest/image_bulk_ingest/",
          checkImage: "/api/request/%%ID%%/check_image/",
        },
        images: {
          getList: "/api/imageviewer/",
          getThermal: "/api/image/fetch_thermal_data/?image_id=%%ID%%",
        },
        structureNavigation:
          "/api/structure/%%ID%%/navigate_structure/?request=%%REQUEST_ID%%",
      },
    };
  }

  async getStructureMetaData(requestId, structureId) {
    return this.getRequest(
      this.apiUrls.info.structureMetadata
        .replace("%%REQUEST_ID%%", requestId)
        .replace("%%STRUCTURE_ID%%", structureId)
    );
  }

  async getTemplateTypes() {
    return this.getRequest(this.apiUrls.info.templateTypes);
  }

  async getTemplateSchema(requestId, templateType) {
    return this.getRequest(
      this.apiUrls.info.templateSchema
        .replace("%%REQUEST_ID%%", requestId)
        .replace("%%TEMPLATE_TYPE%%", templateType)
    );
  }

  async updateInfoTableData(requestId, structureId, templateType, data) {
    return this.putRequest(
      this.apiUrls.info.overwriteData
        .replace("%%REQUEST_ID%%", requestId)
        .replace("%%STRUCTURE_ID%%", structureId)
        .replace("%%TEMPLATE_TYPE%%", templateType),
      data
    );
  }

  async getCompleteWorkordersList() {
    return this.getRequest(this.apiUrls.workorders.completeList);
  }

  async getWorkordersList(page, pageSize, search) {
    return this.getRequest(
      this.apiUrls.workorders.list
        .replace("%%PAGE%%", page)
        .replace("%%PAGE_SIZE%%", pageSize)
        .replace("%%SEARCH%%", search)
    );
  }

  async getInspectionsSummaryData() {
    return this.getRequest(this.apiUrls.workorders.inspectionsSummary);
  }

  async getWorkordersHeatmap() {
    return this.getRequest(this.apiUrls.workorders.heatmap);
  }

  async getQuickAccessInspections() {
    return this.getRequest(this.apiUrls.workorders.list);
  }

  async getChatNotifications(page, pageSize) {
    return this.getRequest(
      this.apiUrls.workorders.chatNotifications
        .replace("%%PAGE%%", page)
        .replace("%%PAGE_SIZE%%", pageSize)
    );
  }

  async getUpdateNotifications(id, data) {
    return this.patchRequest(
      this.apiUrls.workorders.updateNotifications.replace("%%ID%%", id),
      data
    );
  }

  async readAllImageChatNotifications(data) {
    return this.postRequestJSON(
      this.apiUrls.workorders.readAllImageChatNotifications,
      data
    );
  }

  async bulkReadNotifications() {
    return this.postRequest(this.apiUrls.workorders.bulkReadNotifications);
  }

  async getWorkorderInfo(id) {
    return this.getRequest(this.apiUrls.workorders.view.replace("%%ID%%", id));
  }

  async getMapData(id) {
    return this.getRequest(
      this.apiUrls.workorders.mapData.replace("%%ID%%", id)
    );
  }

  async getWorkorderStructures(id) {
    return this.getRequest(
      this.apiUrls.workorders.structures.replace("%%ID%%", id)
    );
  }

  async getInspectionTableData(
    page,
    pageSize,
    search,
    status,
    severity,
    component,
    id
  ) {
    if (search === "" && status === "" && severity === "" && component === "") {
      // Remove unnecessary parts from the URL
      const baseUrl = this.apiUrls.workorders.tableData;
      const endIndex = baseUrl.indexOf("%%ID%%") + "%%ID%%".length;
      const modifiedUrl = baseUrl.substring(0, endIndex);

      return this.getRequest(
        modifiedUrl
          .replace("%%PAGE%%", page)
          .replace("%%PAGE_SIZE%%", pageSize)
          .replace("%%ID%%", id)
      );
    } else {
      // Keep the original URL with all parameters
      return this.getRequest(
        this.apiUrls.workorders.tableData
          .replace("%%PAGE%%", page)
          .replace("%%PAGE_SIZE%%", pageSize)
          .replace("%%ID%%", id)
          .replace("%%SEARCH%%", search)
          .replace("%%STATUS%%", status)
          .replace("%%SEVERITY%%", severity)
          .replace("%%COMPONENT%%", component)
      );
    }
  }

  async getInspectionStats(id) {
    return this.getRequest(this.apiUrls.workorders.stats.replace("%%ID%%", id));
  }

  async postImagesSoftDelete(data) {
    return this.postRequest(this.apiUrls.workorders.softDeleteImages, data);
  }

  async getStructureIds(id) {
    return this.getRequest(
      this.apiUrls.workorders.structureData.replace("%%REQUEST_ID%%", id)
    );
  }

  async postRestructingImages(data) {
    return this.postRequest(this.apiUrls.workorders.restructingImages, data);
  }

  async postDeviceRequestId(data) {
    return this.postRequest(this.apiUrls.workorders.device, data);
  }

  async getPhotoAnnotated(id) {
    return this.getRequest(this.apiUrls.annotation.image.replace("%%ID%%", id));
  }

  async getImagesList(requestId, structureId) {
    return this.getRequest(
      this.apiUrls.annotation.imagesList
        .replace("%%REQUEST_ID%%", requestId)
        .replace("%%STRUCTURE_ID%%", structureId)
      // .replace("%%CONDITION%%", condition)
    );
  }

  async getPhotoAnnotationDetails(id) {
    return this.getRequest(
      this.apiUrls.annotation.getAnnotation.replace("%%ID%%", id)
    );
  }

  async postInputChatData(input) {
    return this.postRequest(this.apiUrls.annotation.postChat, input);
  }

  async getDownloadImages(str_id, req_id) {
    return this.getRequest(
      this.apiUrls.workorders.downloadImages
        .replace("%%STR_ID%%", str_id)
        .replace("%%REQ_ID%%", req_id)
    );
  }

  async getDownloadAllImages(req_id) {
    return this.postRequest(
      this.apiUrls.workorders.downloadAllImages.replace("%%ID%%", req_id)
    );
  }

  async getDownloadCSVReport(req_id) {
    return this.getRequest(
      this.apiUrls.workorders.downloadCSVReport.replace("%%ID%%", req_id)
    );
  }

  async getStructureFolderId(req_id, str_id) {
    return this.getRequest(
      this.apiUrls.services.orders.structureFolderId
        .replace("%%ID%%", req_id)
        .replace("%%NAME%%", str_id)
    );
  }

  async orderGetUtilityProviders() {
    return this.getRequest(this.apiUrls.services.orders.getProviders);
    //return [{id: 'first', name: 'The First'}, {id: 'second', name: 'The Second'}];
  }
  async orderGetLineNumbers() {
    return this.getRequest(this.apiUrls.services.orders.getLineNumbers);
  }
  async orderGetStructures(lineNumber) {
    return this.getRequest(
      this.apiUrls.services.orders.getStructures.replace("%%ID%%", lineNumber)
    );
  }
  async orderCreate(values) {
    return this.postRequest(this.apiUrls.services.orders.create, values);
  }

  async getUpdateStatus(id, data) {
    return this.patchRequest(
      this.apiUrls.workorders.updateStatus.replace("%%ID%%", id),
      data
    );
  }

  async getImageUploadURL(requestID, fileName) {
    return this.getRequest(
      this.apiUrls.services.uploads.getImageUploadUrl
        .replace("%%ID%%", requestID)
        .replace("%%FILE%%", fileName)
    );
  }

  async getStructuresToNavigate(id, requestID) {
    return this.getRequest(
      this.apiUrls.services.structureNavigation
        .replace("%%ID%%", id)
        .replace("%%REQUEST_ID%%", requestID)
    );
  }

  async getImageIngestInitiate(data) {
    return this.postRequest(
      this.apiUrls.services.uploads.imageIngestEndPoint,
      data
    );
  }

  async getUniqueImages(requestID, data) {
    return this.postRequest(
      this.apiUrls.services.uploads.checkImage.replace("%%ID%%", requestID),
      data
    );
  }

  async getImageList(id) {
    let url = this.apiUrls.services.images.getList;
    if (id) {
      url += "?id=" + id;
    }
    return this.getRequest(url);
  }

  async getThermalData(imageId) {
    return this.getRequest(
      this.apiUrls.services.images.getThermal.replace("%%ID%%", imageId)
    );
  }

  async getStructureTracker(requestId, structureId) {
    let url = this.apiUrls.annotation.getStructureTracker
      .replace("%%REQUEST_ID%%", requestId)
      .replace("%%STRUCTURE_ID%%", structureId);
    return this.getRequest(url);
  }

  async getUpdatePosition(id, data) {
    return this.patchRequest(
      this.apiUrls.annotation.updateImage.replace("%%ID%%", id),
      data
    );
  }

  async uploadImage(requestUrl, formData, progressCallback, key) {
    let accessToken = localStorage.getItem("access_token");

    return axios.put(requestUrl, formData, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
      onUploadProgress: (progress) => progressCallback(progress, key),
    });
  }
  async uploadImagesFinish(formData) {
    let result = await axios.request({
      method: "POST",
      url: this.apiUrls.uploadImagesFinish,
      data: formData,
      headers: {
        Accept: "application/json, text/plain, ",
        "Content-Type": "multipart/form-data",
      },
    });

    return result;
  }

  /**
   * Make GET request to API endpoint
   * @param {string} url a relative API endpoint URL
   * @returns {Object|null} JSON-decoded response
   */
  async getRequest(url) {
    let accessToken = localStorage.getItem("access_token"),
      fullUrl = url.indexOf("https://") !== -1 ? url : this.apiUrlBase + url,
      requestParams = {
        url: fullUrl,
        headers: {
          Accept: "application/json, text/plain, */*",
        },
      };

    if (accessToken) {
      if (!requestParams.headers) {
        requestParams.headers = {};
      }
      requestParams.headers["Authorization"] = "Token " + accessToken;
    }

    return await this._request(requestParams);
  }

  /**
   * Make POST request to API endpoint
   * @param {string} url a relative API endpoint URL
   * @param {Object|null} data data to send
   * @returns {Promise|null} JSON-decoded response
   */
  async postRequest(url, data = null) {
    let accessToken = localStorage.getItem("access_token"),
      fullUrl = url.indexOf("https://") !== -1 ? url : this.apiUrlBase + url,
      requestParams = {
        method: "POST",
        url: fullUrl,
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
        },
        data: data,
      };

    if (accessToken) {
      if (!requestParams.headers) {
        requestParams.headers = {};
      }
      requestParams.headers["Authorization"] = "Token " + accessToken;
    }

    return await this._request(requestParams);
  }

  /**
   * Make PATCH request to API endpoint
   * @param {string} url a relative API endpoint URL
   * @param {Object|null} data data to send
   * @returns {Promise|null} JSON-decoded response
   */
  async patchRequest(url, data = null) {
    let accessToken = localStorage.getItem("access_token"),
      fullUrl = url.indexOf("https://") !== -1 ? url : this.apiUrlBase + url,
      requestParams = {
        method: "PATCH", // Change the method to "PATCH"
        url: fullUrl,
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "multipart/form-data",
        },
        data: data,
      };

    if (accessToken) {
      if (!requestParams.headers) {
        requestParams.headers = {};
      }
      requestParams.headers["Authorization"] = "Token " + accessToken;
    }

    return await this._request(requestParams);
  }

  /**
   * Make PUT request to API endpoint
   * @param {string} url a relative API endpoint URL
   * @param {Object|null} data data to send
   * @returns {Promise|null} JSON-decoded response
   */
  async putRequest(url, data = null) {
    let accessToken = localStorage.getItem("access_token"),
      fullUrl = url.indexOf("https://") !== -1 ? url : this.apiUrlBase + url,
      requestParams = {
        method: "PUT", // Change the method to "PUT"
        url: fullUrl,
        headers: {
          Accept: "application/json, text/plain",
        },
        data: data,
      };

    if (accessToken) {
      if (!requestParams.headers) {
        requestParams.headers = {};
      }
      requestParams.headers["Authorization"] = "Token " + accessToken;
    }

    return await this._request(requestParams);
  }

  /**
   * Make POST request to API endpoint
   * @param {string} url a relative API endpoint URL
   * @param {Object|null} data data to send
   * @returns {Promise|null} JSON-decoded response
   */
  async postRequestJSON(url, data = null) {
    let accessToken = localStorage.getItem("access_token"),
      fullUrl = this.apiUrlBase + url,
      requestParams = {
        method: "POST",
        url: fullUrl,
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

    if (accessToken) {
      if (!requestParams.headers) {
        requestParams.headers = {};
      }
      requestParams.headers["Authorization"] = "Token " + accessToken;
    }

    return await this._request(requestParams);
  }

  /**
   * Perform a request and handle errors, if any
   * @param {Object} requestParams
   * @returns {Promise<*>}
   * @private
   */
  async _request(requestParams) {
    let results = null;

    try {
      let response = await axios.request(requestParams);
      if (response.status !== 200) {
        if (process.env.REACT_APP_DEBUG_MODE === "true") {
          console.log(
            "%cRequest error:",
            "color: white; background-color: red"
          );
          console.log("requestParams", requestParams);
          console.log("response", response);
        } else {
          window.appComponent.authHelper.logout();
          window.location = "/auth";
        }

        results = false;
      } else {
        results = response.data;
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_email");
        localStorage.removeItem("user_org");
        localStorage.removeItem("user_permissions");
        localStorage.removeItem("org");
        localStorage.removeItem("requestData");
        window.location.href = "/auth";
      }
      results = false;
      throw err;
    }

    return results;
  }
}

export default ApiHelper;
