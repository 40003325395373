import React, { useEffect } from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";

const InfoDropdown = ({
  options,
  optionSelected,
  toggleDropdown,
  dropdownRef,
  selectedOption,
}) => {
  const handleOptionSelect = (option) => {
    optionSelected(option);
    toggleDropdown();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      toggleDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleDropdown]);

  return (
    <MenuList style={{ padding: "4px" }}>
      {options.map((option, index) => (
        <MenuItem
          key={index}
          onClick={() => handleOptionSelect(option.id)}
          sx={{
            backgroundColor:
              selectedOption === option?.id ? "#eef1f6" : "inherit",
          }}
        >
          {!option.backgroundColor && !option.color && (
            <ListItemText>
              {option?.name === "Default" ? "-------" : option.name}
            </ListItemText>
          )}
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default InfoDropdown;
