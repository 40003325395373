import React, { useState } from "react";
import { mapDataStyles } from "./Constants";

const MapDataSelector = ({ setSelectedData, selectedData }) => {
    const [hoveredStyle, setHoveredStyle] = useState(null);

    const handleStyleSelect = (style) => {
        setSelectedData(style);
    };

    const handleMouseEnter = (style) => {
        setHoveredStyle(style);
    };

    const handleMouseLeave = () => {
        setHoveredStyle(null);
    };

    return (
        <div className="style-selector-container">
            {Object.keys(mapDataStyles).map((style) => (
                <div
                    key={style}
                    className="style-box"
                    onClick={() => handleStyleSelect(style)}
                    onMouseEnter={() => handleMouseEnter(style)}
                    onMouseLeave={handleMouseLeave}
                >
                    <img
                        src={mapDataStyles[style].image}
                        alt={`${style} map style`}
                        style={{
                            border:
                                (selectedData === style || hoveredStyle === style) &&
                                "2px solid #007bff",
                        }}
                    />
                    <div
                        className="style-label"
                        style={{
                            color:
                                (selectedData === style || hoveredStyle === style) &&
                                "#007bff",
                        }}
                    >
                        {style}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MapDataSelector;
