import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CustomSelect } from "./Constants";

const SingleDropdown = ({
  options,
  optionSelected,
  selectedOption,
  className,
}) => {
  const handleChange = (event) => {
    optionSelected(event.target.value);
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      displayEmpty
      fullWidth
      className={className}
      input={<CustomSelect />}
    >
      {options.map((option, index) => (
        <MenuItem
          key={index}
          value={option.value}
          sx={{ fontFamily: "'Space Grotesk', sans-serif", fontSize: "12px" }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SingleDropdown;
