import React, { useState, useEffect, useRef } from "react";
import "simplebar-react/dist/simplebar.min.css";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Legend } from "chart.js";
import Report from "../../assets/img/icons/report.png";
import Export from "../../assets/img/icons/exportImages.png";
import InspectionsViewTable from "./InspectionsViewTable";
import {
  chartData1,
  chartData2,
  chartData3,
  emptyChartData,
} from "../../helpers/Utils";
import Reset from "../../assets/img/icons/reset.png";
import Tooltip from "@mui/material/Tooltip";
import Divider from "../../helpers/Divider";
import InspectionsDropdown from "../../helpers/InspectionsDropdown";
import Header from "../../helpers/Header";
import Popup from "../../helpers/Popup";
import Dropdown from "../../helpers/Dropdown";
import { defectOptions, statusOptions } from "../../helpers/Constants";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../helpers/Loader";

const TrackingViewPage = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    id: useParams().id,
    stats: [],
    data: {},
    searchTerm: "",
    showDropdownStatus: false,
    showDropdownDefect: false,
    selectedStatus: [],
    selectedSeverity: [],
    selectedComponent: [],
    checkedRows: {},
    headingCheckbox: false,
    totalRowsCount: 0,
    popupMessage: false,
    generalPopupMessage: false,
    showInspections: true,
    statusChange: false,
    showInspectionsDropdown: false,
    activeButton: "Tracking",
  });
  const [apiError, setApiError] = useState(false);
  const [tableLoad, setTableLoad] = useState(true);
  const [components, setComponents] = useState([]);

  const prevStateRef = useRef(state);

  const updateRemoteDate = async () => {
    const promiseInfo = window.apiHelper.getWorkorderInfo(state.id),
      promiseStats = window.apiHelper.getInspectionStats(state.id);

    Promise.all([promiseInfo, promiseStats])
      .then((values) => {
        const data = values[0],
          stats = values[1];
        document.title =
          data.linenumber + " — " + process.env.REACT_APP_PAGE_TITLE;

        setState((prevState) => ({
          ...prevState,
          stats: stats,
          data: data,
        }));
      })
      .catch((err) => {
        err.response.status === 404 && navigate("/accessdenied");
      })
      .finally(() => {
        window.appComponent.setState({
          loaderVisible: false,
        });
      });
  };

  useEffect(() => {
    updateRemoteDate();
  }, []);

  useEffect(() => {
    if (
      Object.keys(state.checkedRows).length !==
      Object.keys(prevStateRef.current.checkedRows).length
    ) {
      setState((prevState) => {
        if (Object.keys(state.checkedRows).length === 4) {
          return { ...prevState, generalPopupMessage: true };
        } else if (Object.keys(prevStateRef.current.checkedRows).length >= 10) {
          return { ...prevState, generalPopupMessage: false };
        }
        return prevState;
      });
    }

    if (state.statusChange !== prevStateRef.current.statusChange) {
      updateRemoteDate();
    }
  }, [state.checkedRows, state.statusChange]);

  const onSearch = (e) => {
    e.preventDefault();
    const searchTerm = document.querySelector("input#annotation-search").value;
    setState((prevState) => ({
      ...prevState,
      searchTerm: searchTerm,
    }));
  };

  const toggleDropdownInspections = () => {
    setState((prevState) => ({
      ...prevState,
      showInspectionsDropdown: !prevState.showInspectionsDropdown,
    }));
  };

  const handleStatusSelect = (selectedStatus) => {
    setState((prevState) => ({
      ...prevState,
      selectedStatus: selectedStatus,
    }));
  };

  const handleSeveritySelect = (selectedSeverity) => {
    setState((prevState) => ({
      ...prevState,
      selectedSeverity: selectedSeverity,
    }));
  };

  const handleComponentSelect = (selectedComponent) => {
    setState((prevState) => ({
      ...prevState,
      selectedComponent: selectedComponent,
    }));
  };

  const handleStatusChange = (val) => {
    setState((prevState) => ({
      ...prevState,
      statusChange: val,
    }));
  };

  const handleResetState = () => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: "",
      showDropdownStatus: false,
      showDropdownDefect: false,
      selectedStatus: [],
      selectedSeverity: [],
      selectedComponent: [],
    }));
    if (document.querySelector("input#annotation-search").value) {
      document.querySelector("input#annotation-search").value = "";
    }
  };

  const updateCheckedRows = (newCheckedRows) => {
    setState((prevState) => ({
      ...prevState,
      checkedRows: newCheckedRows,
    }));
  };

  const updateTotalRowsCount = (count) => {
    setState((prevState) => ({
      ...prevState,
      totalRowsCount: count,
    }));
  };

  const downloadZipFile = (url) => {
    window.appComponent.setState({
      loaderVisible: false,
    });
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "structure_MM115-1_images.zip");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleButtonClick = () => {
    const keyArray = Object.keys(state.checkedRows);

    const downloadNextFile = async (index) => {
      if (index < keyArray.length) {
        const str_id = keyArray[index];
        window.appComponent.setState({
          loaderVisible: true,
        });
        try {
          const result = await window.apiHelper.getDownloadImages(
            str_id,
            state.id
          );
          downloadZipFile(result.url);
        } catch (error) {
          console.error(`Error downloading file for ${str_id}:`, error);
        }
      }

      setTimeout(() => {
        downloadNextFile(index + 1);
      }, 1200);
    };

    downloadNextFile(0);
  };

  const handleGeneralPopupMessage = () => {
    const keys = Object.keys(state.checkedRows);

    if (keys.length > 0) {
      const lastKey = keys[keys.length - 1];
      delete state.checkedRows[lastKey];
    }

    setState((prevState) => ({
      ...prevState,
      checkedRows: state.checkedRows,
      generalPopupMessage: false,
    }));
  };

  const handlePopupMessage = () => {
    setState((prevState) => ({
      ...prevState,
      popupMessage: !prevState.popupMessage,
    }));
  };

  const handlePopupAccept = () => {
    downloadAllImages();
    setState((prevState) => ({
      ...prevState,
      popupMessage: !prevState.popupMessage,
    }));
  };

  const handleHeadingCheckboxChange = () => {
    setState((prevState) => ({
      ...prevState,
      headingCheckbox: !prevState.headingCheckbox,
    }));
  };

  const downloadAllImages = async () => {
    try {
      window.appComponent.setState({
        loaderVisible: true,
      });
      const workordersList = await window.apiHelper.getDownloadAllImages(
        state.id
      );
      window.appComponent.setState({
        loaderVisible: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handledownloadCSVReport = async () => {
    try {
      window.appComponent.setState({
        loaderVisible: true,
      });
      const workordersList = await window.apiHelper.getDownloadCSVReport(
        state.id
      );
      const today = new Date();
      const formattedDate =
        (today.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        today.getDate().toString().padStart(2, "0") +
        "-" +
        today.getFullYear();

      const blob = new Blob([workordersList], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${formattedDate} - Inspection ${state.id}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.appComponent.setState({
        loaderVisible: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  ChartJS.register(ArcElement, Legend);
  let chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const handleActiveButton = (activeButton) => {
    if (activeButton === "Map") {
      navigate("/inspections" + "/" + state.id);
    }
  };

  const inspection = state.data;

  const reportCheck = state.data?.annotation?.count > 0; // Check if there is data to generate report

  if (!inspection) {
    return <></>;
  }

  let displayText = inspection.linenumber
    ? `${inspection.linenumber} / ${state.id} / `
    : "Loading... ";

  return (
    <div className="inspections-view">
      <Header
        middleHeaderComponent={
          <InspectionsDropdown
            activeButtonSelected={handleActiveButton}
            toggleDropdownInspections={toggleDropdownInspections}
            showInspectionsDropdown={state.showInspectionsDropdown}
            activeButton={state.activeButton}
            displayName={displayText}
          />
        }
        navigateBack={"inspectionsList"}
      />

      <div id="main-panel-trackinspection">
        {state.data.linenumber && (
          <>
            {state.popupMessage && (
              <Popup
                text="For selections exceeding 3 rows, given the extensive data, photos will be organized by structure in a Google Drive link and sent to you within the next 24 hours"
                onAccept={handlePopupAccept}
                onReject={handlePopupMessage}
                type="option"
              />
            )}

            {!state.headingCheckbox && state.generalPopupMessage && (
              <Popup
                text="Currently given the extensive data, at a time you can download images for 3 structures only"
                onAccept={handleGeneralPopupMessage}
                onReject={handleGeneralPopupMessage}
                type="info"
              />
            )}

            <div
              className={`${
                window.innerWidth < 1300
                  ? "inspections-view-status-lg"
                  : "inspections-view-status"
              }`}
            >
              {tableLoad ? (
                <Loader />
              ) : (
                <>
                  <div className="stats-segment">
                    <h3>In-Field Progress</h3>
                    <div className="structure-stats">
                      <Doughnut
                        data={
                          state.stats.in_field_progress === 0
                            ? emptyChartData([1])
                            : chartData2([
                                state.stats.total_structures -
                                  state.stats.pending_structures_count,
                                state.stats.pending_structures_count,
                              ])
                        }
                        options={chartOptions}
                      />
                      <div className="structure-stats-text">
                        {state.stats.total_structures -
                          state.stats.pending_structures_count >
                          0 && (
                          <span className="data-collected">
                            Collected:{" "}
                            {state.stats.total_structures -
                              state.stats.pending_structures_count}
                          </span>
                        )}
                        {state.stats.pending_structures_count > 0 && (
                          <span className="data-pending">
                            Pending: {state.stats.pending_structures_count}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="stats-segment">
                    <h3>Inspection Progress</h3>
                    <div className="structure-stats">
                      <Doughnut
                        data={
                          state.stats.annotated_structures_count === 0 &&
                          state.stats.approved_structures_count === 0 &&
                          state.stats.pending_structures_count === 0 &&
                          state.stats.rejected_structures_count === 0 &&
                          state.stats.photos_uploaded_structures_count === 0 &&
                          state.stats.reviewed_structures_count === 0
                            ? emptyChartData([1])
                            : chartData3([
                                state.stats.annotated_structures_count,
                                state.stats.approved_structures_count,
                                state.stats.pending_structures_count,
                                state.stats.rejected_structures_count,
                                state.stats.photos_uploaded_structures_count,
                                state.stats.reviewed_structures_count,
                              ])
                        }
                        options={chartOptions}
                      />
                      <div className="structure-stats-small">
                        {state.stats.pending_structures_count > 0 && (
                          <span className="inspection-pending">
                            Pending: {state.stats.pending_structures_count}
                          </span>
                        )}
                        {state.stats.photos_uploaded_structures_count > 0 && (
                          <span className="inspection-photos-uploaded">
                            Photos Uploaded:{" "}
                            {state.stats.photos_uploaded_structures_count}
                          </span>
                        )}
                        {state.stats.annotated_structures_count > 0 && (
                          <span className="inspection-annotated">
                            Annotated: {state.stats.annotated_structures_count}
                          </span>
                        )}
                        {state.stats.reviewed_structures_count > 0 && (
                          <span className="inspection-reviewed">
                            Reviewed: {state.stats.reviewed_structures_count}
                          </span>
                        )}
                        {state.stats.approved_structures_count > 0 && (
                          <span className="inspection-approved">
                            Approved: {state.stats.approved_structures_count}
                          </span>
                        )}
                        {state.stats.rejected_structures_count > 0 && (
                          <span className="inspection-rejected">
                            Rejected: {state.stats.rejected_structures_count}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="stats-segment">
                    <h3>High Risk Structures</h3>
                    <p>{state.stats.high_risk_structures_count}</p>
                  </div>
                  <Divider />
                  <div className="stats-segment">
                    <h3>Number of Defects</h3>
                    <div className="structure-stats">
                      <Doughnut
                        data={
                          state.stats.high_level_defects === 0 &&
                          state.stats.medium_level_defects === 0 &&
                          state.stats.low_level_defects === 0
                            ? emptyChartData([1])
                            : chartData1([
                                state.stats.high_level_defects,
                                state.stats.medium_level_defects,
                                state.stats.low_level_defects,
                              ])
                        }
                        options={chartOptions}
                      />

                      <div className="structure-stats-text">
                        <span className="issues-high">
                          High: {state.stats.high_level_defects}
                        </span>
                        <span className="issues-medium">
                          Medium: {state.stats.medium_level_defects}
                        </span>
                        <span className="issues-low">
                          Low: {state.stats.low_level_defects}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={`data-filters ${apiError ? "disabled" : ""}`}>
              <div className="data-filters-leftgroup">
                <form
                  action=""
                  autoComplete="off"
                  className="form-group search-group search"
                  onSubmit={onSearch}
                  onBlur={onSearch}
                >
                  <input
                    type="text"
                    className="form-control"
                    id="annotation-search"
                    placeholder="Search..."
                    autoComplete="off"
                  />
                  <button>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.0058 14C5.13982 14 2.0058 10.866 2.0058 6.99997C2.0058 3.13396 5.13979 0 9.0058 0C12.8718 0 16.0058 3.13399 16.0058 7C16.0058 10.866 12.8718 14 9.0058 14ZM9.0058 2.00001C6.24437 2.00001 4.00581 4.23857 4.00581 7C4.00581 9.76143 6.24437 12 9.0058 12C11.7672 12 14.0058 9.76143 14.0058 7C14.0058 4.23857 11.7672 2.00001 9.0058 2.00001Z"
                        fill="#CACACA"
                      />
                      <path
                        d="M1.0058 16C0.739987 16.0015 0.484514 15.8972 0.295804 15.71C-0.0963325 15.321 -0.0989176 14.6879 0.290014 14.2958C0.291944 14.2938 0.293874 14.2919 0.295804 14.29L4.05579 10.53C4.46172 10.1379 5.10867 10.1491 5.50081 10.555C5.89295 10.9609 5.88174 11.6079 5.47582 12L1.7158 15.71C1.52709 15.8972 1.27161 16.0015 1.0058 16Z"
                        fill="#CACACA"
                      />
                    </svg>
                  </button>
                </form>
                <div className="button-dropdown-container">
                  <Dropdown
                    options={statusOptions}
                    optionSelected={handleStatusSelect}
                    selectedOptions={state.selectedStatus}
                    label="Select Status"
                  />
                </div>
                <div className="button-dropdown-container">
                  <Dropdown
                    options={defectOptions}
                    optionSelected={handleSeveritySelect}
                    selectedOptions={state.selectedSeverity}
                    label="Select Severity"
                  />
                </div>
                <div className="button-dropdown-container">
                  <Dropdown
                    options={components}
                    optionSelected={handleComponentSelect}
                    selectedOptions={state.selectedComponent}
                    label="Select Component"
                  />
                </div>
                <Tooltip title="Reset Filters" arrow placement="top">
                  <div className="button-dropdown-container">
                    <button className="reset-button" onClick={handleResetState}>
                      <img
                        src={Reset}
                        alt="reset"
                        className="button-img-reset"
                      />
                    </button>
                  </div>
                </Tooltip>
              </div>
              <div className="data-filters-leftgroup">
                <Tooltip title="Export Photos" arrow placement="top">
                  <div className="button-dropdown-container">
                    {state.headingCheckbox ? (
                      <button
                        className="filter-button"
                        onClick={handlePopupMessage}
                      >
                        <img
                          src={Export}
                          alt="Image Alt Text"
                          style={{ width: "25px" }}
                        />
                        <span>{`Export images (${state.totalRowsCount} structures)`}</span>
                      </button>
                    ) : (
                      Object.keys(state.checkedRows).length > 0 && (
                        <button
                          className="filter-button"
                          onClick={handleButtonClick}
                        >
                          <img
                            src={Export}
                            alt="Image Alt Text"
                            style={{ width: "25px" }}
                          />
                          <span>
                            {`Export images (${
                              Object.keys(state.checkedRows).length
                            } structure${
                              Object.keys(state.checkedRows).length > 1
                                ? "s"
                                : ""
                            })`}
                          </span>
                        </button>
                      )
                    )}
                  </div>
                </Tooltip>
                <Tooltip
                  title={reportCheck ? "Download report" : "No data to report"}
                  arrow
                  placement="top"
                >
                  <div className="button-dropdown-container">
                    <button
                      className="filter-button"
                      onClick={handledownloadCSVReport}
                      disabled={!reportCheck}
                      style={{
                        cursor: !reportCheck ? "default" : "pointer",
                        opacity: !reportCheck ? 0.25 : 1,
                      }}
                    >
                      <img
                        src={Report}
                        alt="Image Alt Text"
                        style={{ width: "25px" }}
                      />
                    </button>
                  </div>
                </Tooltip>
              </div>
            </div>
            <div>
              {
                <InspectionsViewTable
                  searchTerm={state.searchTerm}
                  selectedStatus={state.selectedStatus}
                  selectedSeverity={state.selectedSeverity}
                  selectedComponent={state.selectedComponent}
                  id={state.id}
                  checkedRows={state.checkedRows}
                  updateCheckedRows={updateCheckedRows}
                  headingCheckbox={state.headingCheckbox}
                  handleHeadingCheckboxChange={handleHeadingCheckboxChange}
                  updateTotalRowsCount={updateTotalRowsCount}
                  statusChange={state.statusChange}
                  handleStatusChange={handleStatusChange}
                  apiError={apiError}
                  setApiError={setApiError}
                  tableLoad={tableLoad}
                  setTableLoad={setTableLoad}
                  setComponents={setComponents}
                />
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TrackingViewPage;
