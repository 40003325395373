import React, { Component } from "react";
import { Link } from "react-router-dom";

class AuthPageLoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: process.env.REACT_APP_DEFAULT_EMAIL
        ? process.env.REACT_APP_DEFAULT_EMAIL
        : "",
      password: process.env.REACT_APP_DEFAULT_PASSWORD
        ? process.env.REACT_APP_DEFAULT_PASSWORD
        : "",
      rememberMe: false,
      formSubmitted: false,
      formError: null,
    };

    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onRememberMeChange = this.onRememberMeChange.bind(this);
    this.onLogin = this.onLogin.bind(this);
  }

  onEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onPasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onRememberMeChange(value) {
    this.setState({
      rememberMe: value,
    });
  }

  async onLogin(e) {
    e.preventDefault();
    try {
      let result = await window.appComponent.authHelper.login(
        this.state.email,
        this.state.password
      );

      if (result) {
        window.location = "/";
      } else {
        this.setState({
          formError: "Wrong credentials, please try again.",
        });
      }
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 400) {
        // Handle unauthorized or validation error
        this.setState({
          formError: "Wrong credentials, please try again.",
        });
      } else if (error.response && error.response.status === 500) {
        this.setState({
          formError: "Server error, please try again later.",
        });
      } else {
        this.setState({
          formError: "An error occurred during login. Please try again later.",
        });
      }
    }
  }

  render() {
    return (
      <>
        <form action="/">
          <h1>Login</h1>
          <p className="subheader d-none">
            Don’t have an account?{" "}
            <Link to="/auth/contact-us">Contact Us »</Link>
          </p>

          {this.state.formError && (
            <p className="form-error">{this.state.formError}</p>
          )}

          <div className="form-group">
            <label htmlFor="email-field">Email</label>
            <input
              type="text"
              name="email-field"
              defaultValue={this.state.email}
              className="form-control"
              id="email-field"
              onChange={this.onEmailChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password-field">Password</label>
            <input
              type="password"
              name="password-field"
              defaultValue={this.state.password}
              className="form-control"
              id="password-field"
              onChange={this.onPasswordChange}
            />
          </div>

          <div className="form-group">
            <button
              type="submit"
              className="btn btn-warning"
              onClick={this.onLogin}
            >
              Sign In
            </button>
          </div>
        </form>
      </>
    );
  }
}

export default AuthPageLoginForm;
