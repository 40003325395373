import React, { useEffect } from "react";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import ListItemText from "@mui/material/ListItemText";

const SingleDropdown = ({
  options,
  optionSelected,
  toggleDropdown,
  dropdownRef,
}) => {
  const handleOptionSelect = (option) => {
    optionSelected(option);
    toggleDropdown();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      toggleDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggleDropdown]);

  return (
    <MenuList style={{ padding: "4px" }}>
      {options.map((option, index) => (
        <MenuItem key={index} onClick={() => handleOptionSelect(option.value)}>
          {option.backgroundColor && option.color && (
            <Chip
              label={option.label}
              sx={{
                backgroundColor: option.backgroundColor,
                color: option.color,
                fontWeight: 500,
                fontSize: "13.5px",
              }}
            />
          )}
          {option.icon && (
            <img
              src={option.icon}
              alt={`${option.label} Icon`}
              className="button-img"
              style={
                option.rotation && {
                  transform: `rotate(${option.rotation}deg)`,
                }
              }
            />
          )}
          {!option.backgroundColor && !option.color && (
            <ListItemText>{option.label}</ListItemText>
          )}
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default SingleDropdown;
